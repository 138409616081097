/*
 * Progress Bar
 */

.mi-progress{
  &-title{
    font-weight: 600;
    margin-bottom: 3px;
  }

  &-inner{
    display: flex;
    align-items: center;
  }

  &-percentage{
    flex: 0 0 0;
  }

  &-container{
    margin-bottom: 3px;
    background: $color-border;
    display: block;
    height: 15px;
    width: 100%;
    position: relative;
  }

  &-active{
    position:absolute;
    height: 15px;
    background:$color-theme;
    top:0;
    left:0;
    display:flex;
    align-items:bottom;
    justify-content:flex-end;
    padding-right:10px;

  }

.progress-bar {
    border-radius: 0% !important;
    border:solid 1px #fff;
    border-right-width: 5px #131418;
  }
.progress-bar:last-child {
    border-right-width: none;
  }

.bg-success{
  background-color: $color-theme !important;
}

.bg-warning{
  background-color: #6b6b6b !important;

}

.bg-warning:last-child{
  border-right:none !important;
}
}